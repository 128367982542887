import { CopyOutlined } from '@ant-design/icons';
import React,{useState} from 'react';

import {
  AutoComplete,
  Button,
  Cascader,
  Col,
  DatePicker,
  Input,
  InputNumber,
  Row,
  Select,
  Tooltip,
} from 'antd';

const { Option } = Select;

function AdvancedSearch({ setSearchQuery, setKey}) {

  const [selectedOption, setSelectedOption] = useState("projectId");

  function handleSelectChange(value) {
    setKey(value);
    setSelectedOption(value)
  }

    return (
        <div >     
    <Input.Group style={{background:"black", width:"800px"}} compact>
    <Select value={selectedOption} onChange={handleSelectChange}>
        <Option value="projectId">ProjectId</Option>
        <Option value="companyId">CompanyId</Option>
      </Select>
      <Input
        style={{
          width: '50%',
        }}
        placeholder="type here to search"
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <InputNumber />
    </Input.Group>
        </div>
    );
}

export default AdvancedSearch;