import http from "./httpService";
// import { apiUrl } from "../config.json";

// const apiEndpoint = apiUrl + "/companies";
const apiEndpoint = "/project";

const apiPoint = process.env.REACT_APP_API_POINT;

 export function getProjects(record, type) {
  return http.get(apiEndpoint + "/admin/" + record+ "/" + type);
 }

 export function allListingDetails(listingId) {
  return http.get(apiEndpoint + "/getListing/" + listingId);
}



/*-------------- This one is outlier ----------------------------------*/

export function listingApproval(listingId, selection){
  return http.post(apiPoint + "api"+"/"+"listings"+ "/approval/" + listingId,{
    status:"active",
    taskIds: selection
  });
 }





// export function createListing(obj) {
//   return http.post(apiEndpoint, {
//     streamerName: obj.streamerName,
//   });
// }

// export function updateListing(listingId, req) {
//   console.log(listingId);
//   return http.put(apiEndpoint + "/" + listingId, req);
// }

// export function addTeammate(companyId, body) {
//   return http.put(apiEndpoint + "/invite/" + companyId, {
//     id: body.id,
//   });
// }



// export function allCompanyDetails(companyId) {
//   return http.get(apiEndpoint + "/" + companyId);
// }

// export function changePostOrder(companyId, posts) {
//   return http.put(apiEndpoint + "/" + companyId + "/posts", posts);
// }

// export function deletePost(companyId, postId) {
//   return http.delete(apiEndpoint + "/" + companyId + "/" + postId);
// }

// export function updatePost(companyId, postId, req) {
//   return http.put(apiEndpoint + "/" + companyId + "/" + postId, req);
// }

// export function getAPost(companyId, postId) {
//   return http.get(apiEndpoint + "/" + companyId + "/" + postId);
// }

// export function customiseBrand(companyId, body) {
//   return http.put(apiEndpoint + "/customize/" + companyId, {
//     borderColor: body.borderColor,
//     borderThinkness:body.borderThinkness,
//     background: body.background,
//     fontColor: body.fontColor,
//     primaryButton: body.primaryButton,
//     secondaryButton: body.secondaryButton,
//     primaryFont: body.primaryFont,
//     secondaryFont: body.secondaryFont,
//     toastBackground:body.toastBackground,
//     toastFont:body.toastFont,
//     toastButtonColor:body.toastButtonColor,
//     toastButtonFont:body.toastButtonFont,
//   });
// }

// export function addAPin(companyId, attachmentId, req) {
//   return http.post(apiEndpoint + "/" + companyId + "/" + attachmentId, req);
// }

// export function deleteAPin(companyId, postId, attachmentId ) {
//   return http.delete(apiEndpoint + "/" + companyId + "/" + postId + "/" + attachmentId);
// }

// export function changePinOrder(companyId, postId, pins) {
//   return http.put(apiEndpoint + "/" + companyId + "/" + postId + "/pins", pins);
// }

// export function updatePin(companyId, postId, pinId, req) {
//   return http.put(apiEndpoint + "/" + companyId + "/" + postId + "/" + pinId, req);
// }

