import React from 'react';
import Toggle from "../common/Toggle"
import { FcDocument} from "react-icons/fc";
import {getPageType} from "../helper/listingHelper";


function RectangleCards({extendData, selection,setSelection}) {



const url= process.env.REACT_APP_API_POINT;

const handleClick =(item)=>{

let keyword = getPageType(item.taskFeature)

    window.open(url  + keyword  + "/" + extendData.listingId  +"/" +item._id)
}

const handleSelection =(item) =>{



    let newValue = [...selection];
    if(newValue.includes(item))
{

    const index= newValue.indexOf(item);
    newValue.splice(index, 1);
    setSelection(newValue);
    return;
}



    newValue.push(item);
    setSelection(newValue);

 

    }



    if(extendData&& extendData.tasks)
    return (
        <div>
            {extendData.tasks.map((item, index) => (
               <div key={index} style={{marginBottom:"60px"}}className='rectangleBox'>
                   <div className='wideapart'>
                       <div >{item.taskTitle}</div>
                       <div>{item.taskAmount}  </div>
                   </div>
                   <div>{item.taskDescription}</div>
                   <hr/>
                   <div className='wideapart'>
                       <div><em>{item.taskFeature}</em></div>
                       <div>{item.taskType}</div>
                   </div>
               
                   <div>
                       
                   <Toggle 
                              checked={item.isPublished}
                              onChange={() => handleSelection(item._id)}
                              size="large"/>
                   
                   
                   
                   
                   </div>   
                   <div className="flexrow" style={{marginTop:"30px"}}>
                       <div onClick={() => handleClick(item)}>
                           <div><FcDocument/></div>   
                           <p>Visit page</p>
                       </div>
                   </div>   
               </div>
              ))}
        </div>
    );

    else return null;
}

export default RectangleCards;