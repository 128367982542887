import React, { useContext, useEffect, useState } from "react";
import * as userService from "./services/userService";
// const UserContext = React.createContext();
// export default UserContext;

const UserContext = React.createContext();

export function useUserContext() {
  return useContext(UserContext);
}

export function UserProvider({ children }) {
  const [user, setUser] = useState();

  useEffect(() => {
    const fetchData = async () => {
      // const { data } = await userService.allUserDetails();
      // setUser(data);
    };

    fetchData();
  }, []);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}
