import React, { useState, useEffect } from 'react';
import * as extractionService from "../services/extractionService";
import TableComponent from '../common/TableComponent';
import { BiDownload } from "react-icons/bi";
import { message } from "antd";
import { useHistory} from "react-router";

function Extraction(props) {

    const [extractions, setExtractions] = useState([])

    const history = useHistory();


    useEffect(() => {
        const fetchData = async () => {
            const type="extraction"
          const { data } = await extractionService.allExtraction(type);
          console.log(data)
          setExtractions(data);
        };
        fetchData();
      }, []);


      const getClassNames =(status)=>{
        if(status!=="finished")
        return "opacityless"
        
        return ""
        }

        
        const handleDownload = (fileName, finalOutput, extractionPipelineStatus) => {

            if(extractionPipelineStatus!=="finished")
            return message.error("Please wait. We're still processing your document")
      
            // Create a link element
            const link = document.createElement('a');
            link.href = finalOutput;
            link.target = '_blank';
            link.download = fileName; // Set the default file name for the download
        
            // Append the link to the body
            document.body.appendChild(link);
        
            // Trigger the click event
            link.click();
        
            // Remove the link from the body
            document.body.removeChild(link);
          };
      
        
      
       const getButtonColor =(item)=>{
           
              console.log("item", item)
      
            return item==="finished"? "outlinebutton  outlinebutton--green" : item=== "failed"? "outlinebutton outlinebutton--red" :"outlinebutton  outlinebutton--grey";
      
        } 


        useEffect(() => {
            const handleKeyDown = (event) => {
           if (event.metaKey && event.key === 'u') {
                history.push("/");
              }
            };
        
            window.addEventListener('keydown', handleKeyDown);
        
            return () => {
              window.removeEventListener('keydown', handleKeyDown);
            };
          }, [history]);


          function getCellColor(item) {
            const remaining = item.projectExtractionLimit - (item.usage && item.usage.current ? item.usage.current.pagesExtracted : 0);
            if (remaining < 0) {
              return '#ff4c00';
            } else if (remaining < 100) {
              return '#ffab00';
            }
            return ''; // Default color or specify another color
          }

  
    return (
        <div>
         <table className='tableStyle'>
      <thead style={{opacity:"0.6"}}>
        <tr>
          <th>Etag</th>
          <th>CorpusID</th> 
          <th>ETA</th>
          <th>extractionPipelineStatus</th>
          <th>Current</th>
          <th>Allowed</th>
          <th>ProjectName</th>
          <th>fileName</th>
          <th>projectId</th> 
          <th>finalOutput</th> 
          <th>Error Detail</th>
          <th>extractionHTTPErrorCode</th> 
          <th></th> 
        </tr>
      </thead>
    
      <tbody>
        {extractions.map((item, index) => (
          <tr style={{opacity:"0.6"}} key={`index${item._id}`}>
            {/* <td onClick={() => handleProfilePage(project.company)}><CgProfile/></td> */}
            {/* <td><img className='profileImage' src={project.company.streamerImage}></img></td> 
            <td>{project.company.streamerName}</td> 
            <td>{project.company.email}</td> 
            <td>{project.company.bio}</td>
            <td onClick={() => handleRow(project)}><button style={{width:"auto"}}className='btnn btnn-secondary'>View</button></td> 
            <td className={getStripeClasses(project.company.isStripeConnected)}>{project.company.isStripeConnected === true ? "true" : "false"}</td>  
            <td className={getClassNames(project.company.listingStatus)}>{project.company.listingStatus}</td>  */}
            <td>{item.etag}</td> 
            <td >{item.corpusId}</td> 
            <td>{item.ETA}</td> 
            <td className={getButtonColor(item.extractionPipelineStatus)}>{item.extractionPipelineStatus}</td> 
            <tr style={{opacity:"0.6"}} key={`index${item._id}`}>
  <td style={{ background: getCellColor(item) }}>
    {item.usage && item.usage.current && item.usage.current.pagesExtracted}
  </td>
  <td style={{ background: getCellColor(item) }}>
    {item.projectExtractionLimit}
  </td>

</tr>
            {/* <td className={getColor(project.company.accountType)} >{project.company.accountType}</td>  */}
            <td >{item.projectName}</td> 
            <td >{item.fileName}</td> 
            <td >{item.projectId}</td> 
            <td className={getClassNames(item.extractionPipelineStatus)}>
                      <BiDownload style={{ cursor: "pointer", margin: "30px" }} onClick={() => handleDownload(item.fileName, item.finalOutput, item.extractionPipelineStatus)} />
            </td>
            <td >{item.errorDetail}</td> 
            <td >{item.extractionHTTPErrorCode}</td> 
            {/* <td><button style={{cursor:"pointer"}}onClick={()=>handleToken(project.createdBy._id)} >Login</button></td> */}

       
          </tr>
        ))}
      </tbody>

    </table>
            
        </div>
    );
}

export default Extraction;