import http from "./httpService";
// import { apiUrl } from "../config.json";

// const apiEndpoint = apiUrl + "/users";
const apiEndpoint =  "/user";

export async function Login(user) {
  const { data: jwt } = await http.post(apiEndpoint + "/" + "login", {
    email: user.email,
    password: user.password,
  });
  localStorage.setItem("token", jwt);

}

export function getToken(userId, secret) {
  return http.post(apiEndpoint + "/" + "adminsupport" , 
  {
    userId: userId,
    secretString: secret,
}
  );
}

export function updateUser(req) {
  return http.put(apiEndpoint, req);
}


export function allUsersCount() {
  return http.get(apiEndpoint + "/tryadmin");
}

export function verifyUser(req) {
  return http.put(apiEndpoint + "/verify-email", { token: req });
}

export function acceptInvite(token, password) {
  return http.put(apiEndpoint + "/invite-email", {
    token: token,
    password: password,
  });
}
