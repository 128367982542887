import React, { useState } from "react";
import Input from "./InputComponent.jsx";
import Joi from "joi-browser";

function Form({
  inputField,
  errorMessage,
  template,
  schema,
  buttonInfo,
  onSubmit,
  options,
  getValue
}) {
  const [data, setData] = useState(inputField);
  const [error, setError] = useState({ errorMessage });
  const [schemaCheck] = useState(schema);


  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(data, schemaCheck, options);

    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemaNew = { [name]: schemaCheck[name] };
    const { error } = Joi.validate(obj, schemaNew);
    return error ? error.details[0].message : null;
  };

  const handleChange = ({ currentTarget: input }) => {
    let newData = { ...data };
    let newError = { ...error };
    const errorMessage = validateProperty(input);
    if (errorMessage) newError[input.name] = errorMessage;
    else delete newError[input.name];
    newData[input.name] = input.value;
    if(getValue)getValue(newData)
    setData(newData);
    setError(newError);
  };



  // const handleSelect = (selectedOption) => {
  //   let newData = { ...data };
  //   newData.matchType = selectedOption.value;
  //   setData(newData);
  // };

  const getButtonClasses = (style) => {
    let classes = style === "normal" ? "btnn " : "btnn btnn-strech ";

    return (classes += validate() ? "btnn-disable " : "btnn-primary");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate();

    setError(errors || {});
    if (errors) return;
    onSubmit(data);
  };

  return (
    <form>
      {template.map((item, index) => (
        <Input
          key={index}
          name={item.name}
          value={data[item.name]}
          label={item.label}
          type={item.type}
          placeholder={item.placeholder}
          onChange={handleChange}
          error={error[item.name]}
          style={item.style}
          options={item.options}
        />
      ))}

      <button
        onClick={handleSubmit}
        disabled={validate()}
        className={getButtonClasses(buttonInfo.style)}
      >
        {buttonInfo.label}
      </button>
    </form>
  );
}

export default Form;
