import http from "./httpService";
// import { apiUrl } from "../config.json";

// const apiEndpoint = apiUrl + "/users";
const apiEndpoint =  "/getextraction";

export function allExtraction(type) {
    return http.get(apiEndpoint + "/tryadmin/" + type);
  }
  
