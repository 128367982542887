import React from "react";


const Input = ({
  name,
  label,
  value,
  error,
  onChange,
  type,
  style,
  placeholder,
  options,
}) => {
  return ( 
    <div>
      {type === "text" && (
  <div>
  <input
      value={value}
      placeholder={label}
      onChange={onChange}
      className="input--custom input--dark"
      id={name}
      name={name}
      type={type}
  />
  {error && <div className="alertmessage ">{error}</div>}
</div>


      )}

      {type === "password" && (
        <div>
          <div>
            {/* <label className="label-style" htmlFor={name}>
              {label}
            </label> */}
            <input
              key={name}
              value={value}
              onChange={onChange}
              className="input--custom input--dark"
              placeholder={label}
              id={name}
              name={name}
              type={type}
            />
            {error && <div className="alertmessage ">{error}</div>}
          </div>
        </div>
      )}

      {type === "email" && (
        <div>
          <div>
            {/* <label className="label-style" htmlFor={name}>
              {label}
            </label> */}
            <input
              key={name}
              value={value}
              onChange={onChange}
              className="input--custom input--dark"
              placeholder={label}
              id={name}
              name={name}
              type={type}
            />
            {error && <div className="alertmessage ">{error}</div>}
          </div>
        </div>
      )}

      {type === "textArea" && (
        <div>
          <div className="input-bundle">
            <label className="label-style" htmlFor={name}>
              {label}
            </label>
            <textarea
              key={name}
              value={value}
              onChange={onChange}
              className="input--custom input--dark"
              placeholder={placeholder}
              id={name}
              name={name}
              type={type}
            />
            {error && <div className="alertmessage ">{error}</div>}
          </div>
        </div>
      )}

      {type === "select" && (
        <div className="form-group">
          <label htmlFor={name}>{label}</label>
          <select
            name={name}
            id={name}
            onChange={onChange}
            className="selectcustom"
            value={value}
          >
            <option value="" />
            {options.map((option) => (
              <option  key={option.id} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {error && <div className="alertmessage">{error}</div>}
        </div>
      )}
    </div>
  );
};

export default Input;
