import { getStatusClassNames } from 'antd/lib/_util/statusUtils';
import React,{ useState, useEffect } from 'react';
import TableComponent from '../common/TableComponent';
import ExtendBar from '../components/ExtendBar';
import * as listingService from "../services/listingService";
import * as userService from "../services/userService";
import * as webhookService from "../services/webhookService";
import { message } from "antd";
import { useHistory} from "react-router";
import Form from "../common/Form";
import Joi from "joi-browser";
import Search from '../common/Search';
import AdvancedSearch from '../common/AdvancedSearch';

function Home(props) {



  const history = useHistory();

    const [data, setData] = useState("");
    const [sideBar, setSideBar] = useState(false);
    const [extendData, setExtenddata] = useState();
    const [selection, setSelection] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [listing, setListing] = useState("");
    const [searchData, setSearchData] = useState([]);
    const [userCount, setUserCount] = useState(67);
    const [key, setKey] = useState("projectId");
  
    const [record, setRecord] = useState(200);
    const [type, setType] = useState("any");

  
    const handleSubmit = async () => {
      try {
        const response = await listingService.listingApproval(
          extendData.listingId,
          selection
        );

   


        if (response.status === 200) {
          message.success("Success");
          setSideBar(false);
        }
      } catch (ex) {
        if (ex.response && ex.response.status === 400 || ex.response.status <= 500) {
          message.error(ex.response.data);
          return;
        }
      }
    };

    const getTableVisibility =()=>{
      return sideBar?"opacity01":""

  }


  const handleRow =(item)=>{
    setExtenddata(item)
    setSideBar(true)
}


  
    useEffect(() => {
      const fetchData = async () => {
        const { data } = await userService.allUsersCount();
        setUserCount(data.count);
      };
      fetchData();
    }, []);
  
    useEffect(() => {
      const fetchData = async () => {
        const { data } = await listingService.getProjects(record, type);
        console.log(data)
        setData(data);
      };
      fetchData();
    }, []);



    useEffect(() => {



      const fetchData = async () => {

        if (searchQuery) {
           
          
             if(key==="projectId")
             {
             let workingArray =[...data.projects]
         
            let postsReceived = workingArray.filter((m) =>
              m._id.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setSearchData(postsReceived);
            return; 
          }

          if(key==="companyId")
          {
          let workingArray =[...data.projects]
      
         let postsReceived = workingArray.filter((m) =>
           m.company._id.toLowerCase().includes(searchQuery.toLowerCase())
         );
         setSearchData(postsReceived);
         return; 
       }
            
          }
          



    };
    fetchData();  
        // else if (key === "companyId") {
        //   // filter based on companyId
        //   return item.projects.some(
        //     (project) => project.company._id === searchQuery
        //   );
        // } else {
        //   // invalid key
        //   return false;
        // }
      
    }, [key, searchQuery]);


    useEffect(() => {
      const handleKeyDown = (event) => {
        if (event.metaKey && event.key === 'j') {
          history.push("/extraction");
        } 
      };
  
      window.addEventListener('keydown', handleKeyDown);
  
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, [history]);

  
    const handleWebhook =()=>{
     const response = webhookService.adminWebhookCall()
     if(response.status===200)
     message.success("webhook called")
    }

   

    const calculateProjectPercentage = (UserCount, ProjectCount) => {
      const percentage = (ProjectCount / UserCount) * 100;
      return `${percentage.toFixed(2)}%`;
    };

    
      if(data!=="") {
        return (
          <div style={{width:"100vw", height:"100vh"}}>
            {/* <img src ={`${apiPoint}${item.postImage}`}></img> */}
            <div style={{alignItems: "center"}}className='flexrow wideapart'>
              <AdvancedSearch setKey={setKey}  setSearchQuery={ setSearchQuery}/>
              <div>
                {/* <button style={{background:"black", color:"white", width:"10px"}} className='btnn'>100</button>
                <button style={{background:"black", color:"white",  width:"10px"}} className='btnn'>200</button> */}

                <div style={{background:"black", color:"white"}} className='btnn' onClick={handleWebhook}>Call Webhook<span></span></div>
                <div style={{background:"black", color:"white"}} className='btnn'>ProjectCount: <span>{data.projectCount}</span></div>
                {userCount&& <div style={{background:"black", color:"white"}} className='btnn'>UserCount: <span>{userCount}</span></div>}
              </div>
            </div>
            <div className='flexrow'>
              <div className={getTableVisibility()} style={{overflow:"auto"}}>
              <TableComponent data={searchQuery===""? data.projects: searchData} handleRow={handleRow}/>   
              </div>
              <div style={{overflow:"auto"}}>
                <ExtendBar selection={selection} setSelection={setSelection} sideBar={sideBar} extendData={extendData} setSideBar={setSideBar} handleSubmit={handleSubmit}/>
              </div>
            </div>
          </div>
        );
      }
    
      
      return null;
    }
    
    export default Home;
    