import { Switch } from "antd";

function Toggle({ onChange, checked }) {


  return (
    <div>
      <Switch size="small" defaultChecked={checked} onChange={onChange} />
    </div>
  );
}

export default Toggle;
