import { Button, Modal } from 'antd';
import { useState } from 'react';
import CopyInput from './CopyInput';

const ModalCall = ({isModalOpen, setIsModalOpen, setSecret,GenerateToken, token, setToken}) => {

    const [formState, setFormState] = useState("first")


  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    GenerateToken()
    setFormState("2nd")

  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setFormState("first")
    setToken("")
  };

  const handleChange =(e)=>{
    setSecret(e.target.value)
  }

  



  return (
    <>
      <Button type="primary" onClick={showModal}>
        Open Modal
      </Button>
      <Modal title="Basic Modal" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        {formState === "first" ? (
          <div>
            <input onChange={handleChange} />
          </div>
        ) : (
          <CopyInput title="access" value={token} />
        )}
      </Modal>
    </>
  );
};



  export default ModalCall;
  