import React from "react";

const Search = ({ value, onChange, onSearch }) => {
  return (
    <input
      type="text"
      name="query"
      className="searchbox"
      placeholder="Search a URL and then re-arrange cards to change the order of appearance on a specific page."
      value={value}
      onChange={(e) => onSearch(e.currentTarget.value)}
    />
  );
};

export default Search;
