import React from 'react';
import { CgCloseO} from "react-icons/cg";
import RectangleCards from './RectangleCards';


function ExtendBar({sideBar, extendData,setSideBar,selection,setSelection,handleSubmit}) {

    const getClasses =()=>{
        return sideBar===false? "hide":"profileHeader"
    }

    const handleClose =()=>{
        setSideBar(false)
    }

 console.log("extendData", extendData)

    return (
        <div className={getClasses()}>
  <div onClick={handleClose} style={{width:"100px", color:"white"}}><CgCloseO/></div>
              <div>
      <div>
          <p>Total tasks count</p>
          {extendData && <p>{extendData.taskCount}</p>}
      </div>

      <div>
          <p>Total template count</p>
          {extendData && <p>{extendData.templateCount}</p>}
      </div>
  </div>
  {/* <CgCloseO className='closeButton'/> */}
  <RectangleCards extendData={extendData} selection={selection} setSelection={setSelection}/>

  <div className='flexrow flexrow--rigid'>
      <button onClick={() => setSideBar(false)} style={{background:"#F96666", marginRight:"100px"}}className='floatbutton'>X</button>
      <button onClick={handleSubmit} className='floatbutton'>Submit</button>

  </div>

             </div> 
      
    );
}

export default ExtendBar;