import React,{useState, useEffect} from 'react';
import { useHistory} from "react-router";
import { CgProfile } from "react-icons/cg";
import * as userSe from "../services/userService";
import CopyInput from '../common/CopyInput';
import Modal from '../common/Modal'
import { message} from "antd";
import ModalCall from '../common/Modal';
import * as userService from "../services/userService";


function TableComponent({data, handleRow }) {


  const url= process.env.REACT_APP_API_POINT;

  const history = useHistory();

  const [token, SetToken] = useState("")
  const [secret, setSecret] = useState("")
  const [userId, setUserID] = useState("")

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formState, setFormState] = useState("form")

  const apiPoint = process.env.REACT_APP_API_POINT; 

const getClassNames =(item)=>{
return item==="underReview"? "roundContainer roundContainer--red":""
}


const handleProfilePage =(item)=>{

      window.open(url  +  item.streamerName);
  }


const getColor =(item)=>{
  return item === "ada" ? "roundContainer roundContainer--red" :
  item === "scrolls" ? "roundContainer roundContainer--green" :
  (item === "magna" || item === "turin" || item === "rosetta") ? "roundContainer roundContainer--yellow" :
  "";

}




const handleToken =(item)=>{
  setIsModalOpen(true) 
  setUserID(item)

}

const GenerateToken = async () =>{
  const response = await userService.getToken( userId, secret)
SetToken(response.data.token)
}



const handleOk = () => {
  setIsModalOpen(false);
};


const handleCancel = () => {
  setIsModalOpen(false);

};
const handleCopy =()=>{
  message.success("ProjectID copied");
}








 return (
      
      <table className='tableStyle'>
      <thead style={{opacity:"0.6"}}>
        <tr>
          <th>ProjectId</th>
          <th>Project Name</th> 
          <th>Company Name</th>
          <th>AccountType</th>
          <th>Allowed questions</th> 
          <th>HealthStatus</th>
          <th>CompanyId</th> 
          <th>Email</th> 
          <th></th> 
        </tr>
      </thead>
    
      <tbody>
        {data.map((project, index) => (
          <tr style={{opacity:"0.6"}} key={`index${project._id}`}>
            {/* <td onClick={() => handleProfilePage(project.company)}><CgProfile/></td> */}
            {/* <td><img className='profileImage' src={project.company.streamerImage}></img></td> 
            <td>{project.company.streamerName}</td> 
            <td>{project.company.email}</td> 
            <td>{project.company.bio}</td>
            <td onClick={() => handleRow(project)}><button style={{width:"auto"}}className='btnn btnn-secondary'>View</button></td> 
            <td className={getStripeClasses(project.company.isStripeConnected)}>{project.company.isStripeConnected === true ? "true" : "false"}</td>  
            <td className={getClassNames(project.company.listingStatus)}>{project.company.listingStatus}</td>  */}
            <td>{project._id}</td> 
            <td >{project.name}</td> 
            <td >{project.company.name}</td> 
            <td className={getColor(project.company.accountType)} >{project.company.accountType}</td> 
            <td>{project.company.allowedQuestions}</td> 
            <td >{project.company.healthStatus===true?"true" :"false"}</td> 
            <td >{project.company._id}</td> 
            <td>{project.createdBy ? (project.createdBy.email ? project.createdBy.email : project.createdBy) : null}</td>
            <td><button style={{cursor:"pointer"}}onClick={()=>handleToken(project.createdBy._id)} >Login</button></td>

       
          </tr>
        ))}
      </tbody>
      {<ModalCall isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setSecret={setSecret} GenerateToken={GenerateToken} token={token} setToken={SetToken}></ModalCall>}
    </table>
   
    
    
    
    
    );
}

export default TableComponent;