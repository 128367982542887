
/*---------------- To get currency icon -----------------------------------*/
export function getCurrencyIcon(currency) {


    return currency === "USD"
      ? "$"
      : currency === "EUR"
      ? "€"
      : currency === "INR"
      ? "Rs"
      : currency === "GBP"
      ? "£"
      : currency === "CAD"
      ? "CAD"
      : currency === "AUD"
      ? "AUD"
      : currency === "NZD"
      ? "NZ$"
      : "$";
  }


  export function getPageType(taskFeature) {

    return taskFeature === "timeSlots"
    ? "picktime"
    : taskFeature  === "seats"
    ? "pickseat"
    : taskFeature  === "amountOptions"
    ? "pickamount"
    : taskFeature  === "chat"
    ? "pickchat"
    : "createrequest";

  }


 